
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































.styleguide {
  color: $c-black;
  background: $c-black-10;
}

.styleguide__title {
  @extend %text-center;
}

[class*='wrapper'] {
  margin-bottom: $spacing * 5;
}

.card-list {
  display: flex;

  .card {
    flex: 1;

    + .card {
      margin-left: $spacing * 3.2;
    }
  }
}
