
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































.home-cta,
[class*='home-cta--'] {
  position: relative;
  overflow: hidden;
  background-color: $c-blue;
  border-radius: 4rem;

  &.is-light {
    display: flex;
    background-color: $c-white;
  }
}

.home-cta__picture {
  @include get-all-space;

  padding-bottom: 0;

  .is-light & {
    left: initial;

    @include mq(m) {
      width: 50%;
    }
  }

  .is-dark & {
    left: initial;

    @include mq($until: m) {
      transform: translateY(20%);
      opacity: 0.5;
    }

    @include mq(m) {
      width: 40%;
    }
  }

  ::v-deep {
    .g-picture {
      padding-bottom: 0;
    }

    img {
      @include image-fit(contain, bottom);

      transform: translate(0, 20px);
      bottom: -40px;
    }
  }
}

.home-cta__text {
  .is-dark & {
    color: $c-white;

    @include mq(m) {
      padding-right: col(1.5, 6.5);
    }
  }
}

.home-cta__content {
  @extend %text-center;

  position: relative;
  z-index: 1;
  padding: $spacing * 4 col(2, 20) $spacing * 16;

  ::v-deep {
    strong {
      font-weight: 900;
      /* stylelint-disable */
      background: -webkit-linear-gradient(
        left,
        $c-gradient-light,
        $c-gradient-dark
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      /* stylelint-enable */

      .is-dark & {
        /* stylelint-disable */
        background: -webkit-linear-gradient(
          left,
          $c-gradient-light,
          $c-gradient-lightest
        );
        background-clip: text;
      }
    }
  }

  @include mq(m) {
    padding: $spacing * 10 col(6, 18) $spacing * 7 col(1, 18);
    text-align: left;
  }

  @include mq(wrapper) {
    padding: $spacing * 12.5 col(8, 16) $spacing * 8 col(1, 16);

    .is-light & {
      padding: $spacing * 6 col(8, 16) $spacing * 4 col(1, 16);
    }

    .is-dark & {
      padding: $spacing * 6 col(5.5, 16) $spacing * 4 col(1, 16);
    }
  }
}

.home-cta__headline {
  color: $c-blue-30;
}

.home-cta__title {
  @extend %fw-medium;

  margin: $spacing * 0.6 0 $spacing;
  color: $c-white;

  .is-light & {
    color: $c-blue-100;
  }

  @include mq(m) {
    margin: $spacing * 0.5 0 $spacing;
  }
}
