
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































.home-intro,
[class*='home-intro--'] {
  @include fluid(
    margin-top,
    (
      xxs: 30px,
      xl: 90px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      xxs: 70px,
      xl: 180px,
    )
  );

  display: flex;
  justify-content: center;
  align-items: center;
}

.home-intro__content {
  @extend %text-center;

  max-width: 1280px;
  color: $c-blue;
  padding-inline: col(1, 20);
  ::v-deep {
    strong {
      font-weight: 900;
      /* stylelint-disable */
      background: -webkit-linear-gradient(
        left,
        $c-gradient-light,
        $c-gradient-dark
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      /* stylelint-enable */
    }
  }
}
