
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































































.home-join,
[class*='home-join--'] {
  @include fluid(
    margin-bottom,
    (
      xxs: 70px,
      xl: 80px,
    )
  );

  background-color: $c-blue;
  border-radius: 4rem;

  @include mq(l) {
    border-radius: 8rem;
  }
}

.home-join__content {
  @extend %text-center;

  margin-inline: auto;
  padding-inline: col(1, 20);
}

.home-join__content__title {
  max-width: 85rem;
  margin-inline: auto;
  color: $c-blue-20;

  ::v-deep {
    strong {
      font-weight: 900;
      /* stylelint-disable */
      background: -webkit-linear-gradient(
        left,
        $c-gradient-light,
        $c-gradient-lightest
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      /* stylelint-enable */
    }
  }
}

.home-join__content__text {
  max-width: 90rem;
  margin-inline: auto;
  color: $c-blue-20;
  font-weight: 400;
}

.home-join__list {
  // prettier-ignore
  @include fluid(margin-top,(xxs: 40px,xxl: 80px,));

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: $spacing * 2.5 $spacing;
  opacity: 0;
  list-style-type: none;
}

.home-join__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 100%;
  padding: $spacing * 2;
  color: $c-blue-80;
  line-height: 1.5;
  background-color: $c-white;
  border-radius: 4rem;
  transition: 0.3s $ease-out-cubic;

  &.has-link {
    &:hover {
      transform: scale(1.05);
    }
  }

  .icon {
    width: 5rem;
    height: 5rem;
    color: $c-blue-80;
  }

  @include mq(m) {
    flex-basis: calc(100% / 2 - 2rem);
  }

  @include mq(xl) {
    flex-basis: calc(100% / 3 - 4rem);

    .icon {
      max-width: 5rem;
    }

    &:nth-child(3n + 2) {
      margin-top: 20rem;
    }
  }
}

.home-join__item__value {
  @include fluid(
    font-size,
    (
      xxs: 22px,
      xxl: 30px,
    )
  );
}

// .home-join__item__label {
//   color: $c-blue-100;
// }

.home-join__item__link {
  @include get-all-space;

  z-index: 9;
}
