
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































.home-team,
[class*='home-team--'] {
  @include fluid(
    margin-top,
    (
      xxs: 30px,
      xl: 50px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      xxs: 70px,
      xl: 110px,
    )
  );

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.home-team__content {
  @extend %text-center;

  margin-inline: auto;
  max-width: 85rem;
}

.home-team__content__title {
  color: $c-blue;

  ::v-deep {
    strong {
      font-weight: 900;
      /* stylelint-disable */
      background: -webkit-linear-gradient(
        left,
        $c-gradient-light,
        $c-gradient-dark
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      /* stylelint-enable */
    }
  }
}

.home-team__content__text {
  color: $c-blue;
  font-weight: 400;
}

.home-team__list {
  // @extend %list-nostyle;
  // prettier-ignore
  @include fluid(margin-top,(xxs: 40px,xxl: 80px,));

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  list-style-type: none;
}

.home-team__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: $c-blue-80;
  line-height: 1.5;
  transition: 0.3s $ease-out-cubic;
  margin-inline: -1rem;
  transform: rotate(-2.5deg);

  &:hover {
    transform: scale(1.1) rotate(5deg);
  }

  ::v-deep {
    .team-card {
      // prettier-ignore
      @include fluid(height,(xxs: 250px,xxl: 350px,));

      overflow: visible;
    }

    .team-card__content {
      display: none;
    }

    .team-card__picture,
    [class*='team-card__picture--'] {
      height: 100%;
      border-radius: 1.5rem;
    }
  }
}

.home-team__link {
  margin-inline: auto;
}
