
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































































































.home-help,
[class*='home-help--'] {
  @include fluid(
    margin-top,
    (
      xxs: 30px,
      xl: 90px,
    )
  );
  @include fluid(
    padding-bottom,
    (
      xxs: 70px,
      xl: 180px,
    )
  );

  position: relative;
  overflow: hidden;
}

.home-help__title {
  max-width: 74rem;
  color: $c-blue;

  ::v-deep {
    strong {
      font-weight: 900;
      /* stylelint-disable */
      background: -webkit-linear-gradient(
        left,
        $c-gradient-light,
        $c-gradient-dark
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      /* stylelint-enable */
    }
  }
}

.home-help__content-outer {
  gap: col(2, 18);

  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
  }
}

.home-help__content {
  flex-shrink: 0;

  @include mq(m) {
    width: col(7, 18);
    padding-left: col(1, 18);
  }
}

.home-help__content__text {
  color: $c-blue;
  font-weight: 400;
}

.home-help__content__picture-outer {
  width: 100%;

  @include mq(m) {
    display: flex;
    align-items: center;
    gap: col(5, 18);

    .home-help__content__picture {
      &:nth-child(1) {
        // prettier-ignore
        @include fluid(height,(xxs: 300px,xxl: 500px,));

        width: col(6, 18);
      }

      &:nth-child(2) {
        // prettier-ignore
        @include fluid(height,(xxs: 300px,xxl: 300px,));

        aspect-ratio: 1/1;
        width: col(4, 18);
        height: auto;
      }
    }
  }
}

.home-help__content__picture {
  // prettier-ignore
  @include fluid(height,(xxs: 300px,xxl: 400px,));

  flex-shrink: 0;
  margin-top: $spacing;
  padding-bottom: 0;

  @include mq(m) {
    width: col(9, 18);
    margin-top: 0;
  }
}

.home-help__icon {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  fill: transparent;
  stroke: rgba($c-blue-40, 0.5);

  @include mq(l) {
    stroke: $c-blue-40;
  }
}
