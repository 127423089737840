
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































.home__title {
  position: relative;
  z-index: 1;
  color: $c-blue;
}

.home__links__agenda__list {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.home__links__agenda__link {
  @extend %text-center;
}
