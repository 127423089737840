
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































































































































.home-hero {
  --wrapper-width: 100%;

  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 100vh;
  margin-top: -10vh;
  background: rgba($c-blue-20, 0);

  @include mq(m) {
    height: 100vh;
  }
}

.home-hero__main {
  position: relative;
  z-index: 1;
  padding-top: 17.5vh;
}

.home-hero__main__picture {
  @include center-x;

  overflow: hidden;
  width: 100%;
  height: fit-content;
  transform-origin: top;
  clip-path: inset(0% 0% 0% 0%);

  @include mq($until: m) {
    opacity: 0;
  }
}

.home-hero__list {
  @extend %list-nostyle;
  @include center-y;

  display: flex;
  align-items: flex-start;
  height: 65vh;
}

.home-hero__item {
  align-self: center;

  & + & {
    margin-left: $spacing * 3.5;
  }

  @include mq(m) {
    & + & {
      margin-left: $spacing * 5;
    }

    &:first-child {
      align-self: auto;
    }

    &:nth-child(3) {
      align-self: flex-end;
    }
  }
}

.home-hero__item__picture {
  position: relative;
  overflow: hidden;
  width: fit-content;

  img {
    width: auto;
    max-width: 90vw;
    max-height: 70vh;
  }

  li:first-child &,
  li:nth-child(3) & {
    width: var(--wrapper-width);
    height: 0;
    padding-bottom: 150%;
  }

  li:nth-child(3) & {
    padding-bottom: 90%;
  }

  @include mq(s) {
    li:first-child & {
      padding-bottom: 80%;
    }

    li:nth-child(3) & {
      padding-bottom: 70%;
    }
  }

  @include mq($until: m) {
    li:first-child &,
    li:nth-child(3) & {
      img {
        @include image-fit;

        max-width: none;
        max-height: none;
      }
    }
  }

  @include mq(m) {
    height: 40vh;
    opacity: 0;

    img {
      width: auto;
      max-width: none;
      height: 100%;
      max-height: none;
      // transform: scale(1.1)
    }

    li:first-child & {
      width: fit-content;
      height: 48vh;
      padding-bottom: 0;
    }

    li:nth-child(3) & {
      width: fit-content;
      height: 46vh;
      padding-bottom: 0;
    }
  }
}
